/* eslint-disable react/no-array-index-key */
import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface CertificationProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Certification
  title: string
}

const CertificationWrapper = styled.section`
  background: ${(props) => props.theme.color.face.secondary};

  h3 {
    font-size: ${(props) => props.theme.font.size['42']};
  }
`

const Content = styled(ParseContent)`
  @media (max-width: 991px) {
    h3 {
      text-align: center;
    }
  }
`

const StyledImage = styled(Plaatjie)`
  max-width: 200px;
  max-height: 100px;
  width: 100%;

  & img {
    object-fit: contain !important;
    filter: grayscale(1);
  }
`

const CustomStyledImage = styled(Plaatjie)`
  max-width: 200px;
  max-height: 100px;
  width: 100%;

  & img {
    object-fit: cover !important;
    filter: grayscale(1);
  }
`

const Certification: React.FC<CertificationProps> = ({ fields }) => (
  <CertificationWrapper>
    <div className="container py-5">
      <div className="row">
        <div className="col-12 mb-lg-4 mb-5">
          <Content content={fields.title} />
        </div>
      </div>
      <div className="row mb-5">
        {fields?.certification?.map((edge, index: number) => (
          <React.Fragment key={index}>
            <div className="col-lg-2 pl-lg-0 col-12 mb-lg-5 d-flex justify-content-center">
              {edge?.link ? (
                <a href={edge?.link} target="_blank" rel="noreferrer">
                  <CustomStyledImage image={edge?.image} className="mx-auto" alt="" />
                </a>
              ) : (
                <StyledImage image={edge?.image} className="mx-auto" alt="" />
              )}
            </div>
            <div className="col-lg-4 col-12 mb-5 d-flex align-items-center">
              <ParseContent
                content={edge?.description}
                className="text-center text-lg-left"
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  </CertificationWrapper>
)

export default Certification
